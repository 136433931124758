import React, { useEffect, useState } from 'react';
import {Alert, Button, Col, Form, Row, Modal} from 'react-bootstrap';
import ConfirmationModal from '../../components/ConfirmationModal';
import {
  deletePerson,
  getPersons,
  blockPerson,
  unblockPerson
} from '../../helpers/api';
import { useToastNotifications } from '../../helpers/notifications';
import translate from '../../helpers/translations';
import SportsCenterAdministratorModal from './SporsCenterAdministratorModal';
import Splash from "../Splash";
import {useNavMenu} from "../../components/NavMenu/NavMenuProvider";
import SRTable from '../../components/SRTable';
import Checkmark from "../../components/Icons/Checkmark";
import DatePicker from 'react-datepicker';
import {formatDateValue} from "../../helpers/date";

const PAGE_SIZE = 50;

const Users: React.FC = () => {
  const { newToastNotification } = useToastNotifications();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [query, setQuery] = useState<string>('');
  const [role, setRole] = useState<string | null>('USER');
  const [results, setResults] = useState<Person[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const [blockUserId, setBlockUserId] = useState<number | null>(null);
  const [unblockUserId, setUnlockUserId] = useState<number | null>(null);
  const [reason, setReason] = useState("");
  const [blockedUntil, setBlockedUntil] = useState<Date | null>(null);

  const {setActiveHeaderKey, setActiveSidebarKey, setSportsCenter} = useNavMenu();

  // Function to execute when user is blocked
  const handleBlockUser = () => {
    let defaultUntil = new Date()
    defaultUntil.setFullYear(defaultUntil.getFullYear() + 100)
    let until = blockedUntil != null ? blockedUntil : defaultUntil
    blockUserId && blockPerson(blockUserId, {reason: reason, blockedUntil: until.toISOString()})
        .then(() => {
          newToastNotification(translate('block'), translate('operationSuccessful') + '!');
          setResults(prevUsers =>
              prevUsers.map(user =>
                  user.id === blockUserId ? { ...user, blockedUntil: until.toISOString() } : user
              )
          );
        })
        .catch(() => {
          newToastNotification(translate('block'), translate('unexpectedError') + '.', 'danger');
        })
        .finally(() => {
          setBlockUserId(null);
          setReason("");
          setBlockedUntil(null);
        })
  };

  // Function to execute when user is unblocked
  const handleUnblockUser = () => {
    unblockUserId && unblockPerson(unblockUserId, {reason: reason})
        .then(() => {
          newToastNotification(translate('unblock'), translate('operationSuccessful') + '!');
          setResults(prevUsers =>
              prevUsers.map(user =>
                  user.id === unblockUserId ? { ...user, blockedUntil: undefined } : user
              )
          );
        })
        .catch(() => {
          newToastNotification(translate('unblock'), translate('unexpectedError') + '.', 'danger');
        })
        .finally(() => {
          setUnlockUserId(null);
          setReason("");
        })
  };

  const search = () => {
    setLoading(true);
    setErrorMsg(null);
    setPageNumber(0);
    getPersons(query, role, 0, PAGE_SIZE)
      .then(({ data, headers }) => {
        setLoading(false);
        setResults(data);
        setTotalCount(parseInt(headers['x-total-count']));
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        setResults([]);
        setTotalCount(0);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('errorFetchingResults') + '.');
        }
      })
  }

  const submit = (e: any) => {
    e.preventDefault()
    search()
  }

  const loadMoreResults = () => {
    setLoading(true);
    getPersons(query, role, pageNumber + 1, PAGE_SIZE)
      .then(({ data, headers }) => {
        setLoading(false);
        setResults(results.concat(data));
        setPageNumber(pageNumber + 1);
        setTotalCount(parseInt(headers['x-total-count']));
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        setResults([]);
        setTotalCount(0);
        if (data && data.message) {
          setErrorMsg(data.message);
        } else {
          setErrorMsg(translate('errorFetchingResults') + '.');
        }
      })
  }

  const doDelete = () => {
    setDeleteInProgress(true);
    if (deletingId !== null) {
      deletePerson(deletingId)
        .then(() => {
          setDeletingId(null);
          setDeleteInProgress(false);
          newToastNotification(translate('deleteUser'), translate('userSuccessfullyDeleted') + '!');
          search();
        })
        .catch(() => {
          setDeletingId(null);
          setDeleteInProgress(false);
          newToastNotification(translate('deleteUser'), translate('errorDeletingUser') + '.', 'danger');
        })
    }
  }

  useEffect(() => {
    setActiveHeaderKey('users')
    setActiveSidebarKey(undefined)
    setSportsCenter(undefined)
  }, []);

  useEffect(search, [role]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>
    <div className="container mt-3">
      {/* Block User Modal */}
      <Modal show={!!blockUserId} onHide={() => setBlockUserId(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{translate('confirmBlock')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="blockReason" style={{marginBottom: '10px'}}>
              <Form.Label>{translate('blockUserReason')}</Form.Label>
              <Form.Control
                  as="textarea" rows={3}
                  placeholder={translate('enterReason')}
                  value={reason}
                  onChange={(e: any) => setReason(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="blockedUntil">
              <DatePicker
                  placeholderText={translate('blockUntil')}
                  selected={blockedUntil}
                  onChange={date => {
                    date?.setMilliseconds(0)
                    setBlockedUntil(date)
                  }}
                  showTimeSelect
                  timeFormat='HH:mm'
                  timeIntervals={30}
                  dateFormat='dd.MM.yyyy. HH:mm'
                  customInput={<Form.Control />}
                  filterTime={time => time > new Date()}
              />
            </Form.Group>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setBlockUserId(null)}>
            {translate('close')}
          </Button>
          <Button variant="danger" onClick={handleBlockUser}>
            {translate('block')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Unblock User Modal */}
      <Modal show={!!unblockUserId} onHide={() => setUnlockUserId(null)}>
        <Modal.Header closeButton>
          <Modal.Title>{translate('confirmUnblock')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="unblockReason">
              <Form.Label>{translate('unblockUserReason')}</Form.Label>
              <Form.Control
                  as="textarea" rows={3}
                  placeholder={translate('enterReason')}
                  value={reason}
                  onChange={(e: any) => setReason(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setUnlockUserId(null)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleUnblockUser}>
            {translate('unblock')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    {deletingId != null &&
        <ConfirmationModal
            title={translate('confirmDeleting')}
            body={translate('sureDeletingUser') + '?'}
            confirmButtonVariant='danger'
            inProgress={deleteInProgress}
            onConfirm={doDelete}
            onCancel={() => {
              setDeletingId(null);
              setDeleteInProgress(false);
            }}
        />}
    <SportsCenterAdministratorModal
        show={showAddModal}
        onHide={() => setShowAddModal(false)}
        refresh={search}
    />
    {loading && <Splash/>}
    {errorMsg && <Alert variant='danger' style={{fontSize: '0.85rem'}}>
      <div style={{fontWeight: '700'}}>{translate('errorFetchingResults')}</div>
      {errorMsg}
    </Alert>}
    <Form onSubmit={submit}>
      <Row>
        <Col md={6} className='d-flex mb-2'>
          <Form.Control className='mx-1' placeholder={translate('email')} value={query}
                        onChange={e => setQuery(e.target.value)}/>
          <Button type="submit" className='mx-1'>{translate('search')}</Button>
        </Col>
        <Col md={6} className='d-flex mb-2 ' style={{justifyContent: 'end'}}>
          <Button className='mx-1' variant='secondary'
                  onClick={() => setShowAddModal(true)}>{translate('addSportsCenterAdmin')}</Button>
        </Col>
      </Row>
      <Row className="justify-content-center text-center mx-1">
        <Button
            variant={role === 'USER' ? 'secondary' : ''}
            className='shadow-none my-1 mx-1 col-md-auto'
            onClick={() => {
              setRole(role === 'USER' ? null : 'USER')
            }}>
          {translate('users')}
        </Button>
        <Button
            variant={role === 'SPORTS_CENTER' ? 'secondary' : ''}
            className='shadow-none my-1 mx-1 col-md-auto'
            onClick={() => {
              setRole(role === 'SPORTS_CENTER' ? null : 'SPORTS_CENTER')
            }}>
          {translate('sportsCenterOwners')}
        </Button>
        <Button
            variant={role === 'ADMINISTRATOR' ? 'secondary' : ''}
            className='shadow-none my-1 mx-1 col-md-auto'
            onClick={() => {
              setRole(role === 'ADMINISTRATOR' ? null : 'ADMINISTRATOR')
            }}>
          {translate('superAdmins')}
        </Button>
      </Row>
    </Form>
    {results.length === 0 && <Row>
      <Col>
        <h3 className='text-muted mt-5 text-center'>{loading ? `${translate('loading')}...` : translate('noResults')}</h3>
      </Col>
    </Row>}
    {results.length !== 0 && <>
      <SRTable>
        <thead>
        <tr>
          <th>#</th>
          <th>{translate('name')}</th>
          <th>{translate('email')}</th>
          <th>{translate('phoneNumber')}</th>
          <th>{translate('role')}</th>
          {role === 'SPORTS_CENTER' && <th>{translate('sportsCenter')}</th>}
          {role === 'USER' && <th>{translate('blockedUntil')}</th>}
          <th>{translate('id')}</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {results.map((p, index) => <tr key={p.id} className='bg-light'>
          <td className='mx-2'>{totalCount - index}</td>
          <td style={{minWidth: '120px'}}>
            {
              !p.firstName && !p.lastName ? '-' :
                  p.firstName && !p.lastName ? p.firstName :
                      !p.firstName && p.lastName ? p.lastName :
                          `${p.firstName} ${p.lastName}`
            }
          </td>
          <td>{p.email || '-'}</td>
          <td>{p.phoneNumber || '-'}</td>
          <td>
            <div>{translate(p.role)}</div>
          </td>
          {role === 'SPORTS_CENTER' && <td>{p.sportsCenters && p.sportsCenters.length > 0 ? p.sportsCenters.map(sc => sc.name).join(', ') : '-'}</td>}
          {role === 'USER' && <td>{p.blockedUntil ? formatDateValue(p.blockedUntil) : '-'}</td>}
          <td>{p.id}</td>
          <td className='text-end'>
            <div className='text-end d-flex justify-content-end align-items-center'>
              <Button size="sm" variant='danger'
                      onClick={() => setDeletingId(p.id)}>{translate('delete')}</Button>&nbsp;
              {role === 'USER' && (p.blockedUntil ?
                  <Button size="sm" variant="success" onClick={() => setUnlockUserId(p.id)}>
                    {translate('unblock')}
                  </Button> :
                  <Button size="sm" variant="secondary" onClick={() => setBlockUserId(p.id)}>
                    {translate('block')}
                  </Button>
              )}
              {p.hasAcceptedTerms ?
                  <Checkmark height={20} width={20} style={{
                    marginLeft: '14px',
                    marginRight: '-14px',
                    fill: p.hasAcceptedTerms ? '#0d6efd' : 'none'
                  }}/> :
                  <span style={{marginLeft: '14px', marginRight: '-14px', width: '20px'}}/>
              }
            </div>
          </td>
        </tr>)}
        </tbody>
      </SRTable>
      {results.length < totalCount && <Row>
        <Col className='d-flex justify-content-center'>
          <Button variant='link' className='mt-3 mb-5' onClick={loadMoreResults}>{translate('seeMoreResults')}</Button>
        </Col>
      </Row>}
      {results.length === totalCount && <Row>
        <Col className='d-flex justify-content-center'>
          <div className='mt-3 mb-5'>{translate('thatsAllResults')}</div>
        </Col>
      </Row>}
    </>}
  </>;
};

export default Users;
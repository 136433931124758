import React, { useState } from 'react';
import { Alert, Button, Table } from 'react-bootstrap';
import ConfirmationModal from '../../../components/ConfirmationModal';
import SRTable from '../../../components/SRTable';
import { deleteTimeOff } from '../../../helpers/api';
import {formatDate, formatDateValue, openingHoursSorter} from '../../../helpers/date';
import { useToastNotifications } from '../../../helpers/notifications';
import translate from '../../../helpers/translations';
import CenterTimeOffModal from './CenterTimeOffModal';

interface Props {
  sportsCenterId: number;
  openingHours: OpeningHours[];
  timesOff: TimeOff[];
  refresh: () => any;
  courts: Court[];
}

const CenterTimesOff: React.FC<Props> = ({ sportsCenterId, openingHours, timesOff, refresh, courts }) => {
  const { newToastNotification } = useToastNotifications();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [editingTimeOff, setEditingTimeOff] = useState<TimeOff | null>(null);

  const doDelete = () => {
    if (deletingId) {
      setErrorMsg(null);
      setDeleteInProgress(true);
      deleteTimeOff(sportsCenterId, deletingId)
        .then(() => {
          setDeleteInProgress(false);
          setDeletingId(null);
          refresh();
          newToastNotification(translate('deleteTimeOff'), translate('operationSuccessful'))
        })
        .catch(({ response: { data } }) => {
          setDeleteInProgress(false);
          if (data && data.message) {
            setErrorMsg(data.message);
          } else {
            setErrorMsg(translate('unexpectedError') + '.');
          }
        });
    }
  }

  return <div className='d-flex flex-column'>
    {timesOff.length !== 0 &&
        <SRTable>
            <thead>
            <tr>
              <th>{translate('id')}</th>
              <th style={{textAlign: 'center'}}>{translate('from')}</th>
              <th style={{textAlign: 'center'}}>{translate('to')}</th>
              <th style={{textAlign: 'center'}}>{translate('courts')}</th>
              <th style={{textAlign: 'center'}}>{translate('sport')}</th>
              <th style={{textAlign: 'center'}}>{translate('reason')}</th>
              <th/>
            </tr>
            </thead>
          <tbody>
          {timesOff.length !== 0 &&
              timesOff
                  .sort((x1, x2) => x1.from > x2.from ? 1 : (x1.from < x2.from ? -1 : x2.id - x1.id))
                  .map(to => <tr key={to.id}>
                    <td>{to.id}</td>
                    <td style={{textAlign: 'center', whiteSpace: 'nowrap'}}>{formatDateValue(to.from)}</td>
                    <td style={{textAlign: 'center', whiteSpace: 'nowrap'}}>{formatDateValue(to.to)}</td>
                    <td style={{textAlign: 'center'}}>{to.courts && to.courts.length > 0 ? to.courts.map(c => <div
                        key={c.id}>{c.name}</div>) : translate('allCourts')}</td>
                    <td style={{textAlign: 'center'}}>{to.sport ? translate(to.sport) : translate('allSports')}</td>
                    <td style={{textAlign: 'center'}}>{to.reason ? to.reason : '-'}</td>
                    <td className='text-end' style={{whiteSpace: 'nowrap'}}>
                      <Button size="sm" variant='secondary' className='mx-1' onClick={() => {
                        setEditingTimeOff(to);
                        setShowEditModal(true);
                      }}>{translate('edit')}</Button>
                      <Button size="sm" variant='danger' className='mx-1'
                              onClick={() => setDeletingId(to.id)}>{translate('delete')}</Button>
                    </td>
                  </tr>)}
          </tbody>
        </SRTable>
    }
    {timesOff.length === 0 && <h4 className='text-muted my-5 text-center'>{translate('noTimesOffAvailable')}</h4>}
    <Button className='mx-auto px-5' variant='secondary' onClick={() => {
      setEditingTimeOff(null);
      setShowEditModal(true);
    }}>{translate('addTimeOff')}</Button>
    {deletingId && <ConfirmationModal
        title={translate('confirmDeleting')}
        body={<>
          {errorMsg && <Alert variant='danger' style={{fontSize: '0.85rem'}}>
          <div style={{ fontWeight: '700' }}>{errorMsg}</div>
        </Alert>}
        {translate('sureToDeleteTimeOff')}?
      </>}
      confirmButtonVariant='danger'
      inProgress={deleteInProgress}
      onConfirm={doDelete}
      onCancel={() => {
        setDeletingId(null);
        setDeleteInProgress(false);
      }}
    />}
    <CenterTimeOffModal
      show={showEditModal}
      timeOff={editingTimeOff}
      sportsCenterId={sportsCenterId}
      openingHours={openingHours}
      onHide={() => setShowEditModal(false)}
      courts={courts}
      refresh={refresh} />
  </div>;
}

export default CenterTimesOff;